export interface IApiData<T, E> {
  loading: boolean,
  create: (data: E) => Promise<T>,
  update: (data: E) => Promise<T>,
  remove: (id: string) => Promise<any>,
  getList: (page: number, pageSize: number, filters?: { [key: string]: string | undefined; }) => Promise<IApiResponse<T>>,
  getOne: (id: string) => Promise<T>,
  getOneById?: (id: string) => Promise<IApiResponse<T>>,
}

export interface IApiResponse<T> {
  content: T[],
  first: boolean,
  last: boolean,
  number: number,
  numberOfElements: number,
  size: number,
  totalElements: number,
  totalPages: number,
}

export interface Category {
  id: string,
  name: string,
  hidden: boolean,
  type: CategoryType,
  imageUrl?: string,
  subCategories?: Subcategory[],
}

export enum CategoryType {
  DEFAULT = 'DEFAULT',
  SPECIAL = 'SPECIAL',
  VERTICAL = 'VERTICAL',
  OCCASION = 'OCCASION',
  THEMED = 'THEMED',
}

export interface CategoryInput extends Omit<Category, 'id' | 'subCategories'> {
  id?: string,
}

export interface Subcategory {
  id: string,
  name: string,
  category: Omit<Category, 'subCategories'>,
}

export interface SubcategoryInput extends Omit<Subcategory, 'id' | 'category'> {
  id?: string,
  category: string,
}

export interface Brand {
  id: string,
  name: string,
}

export interface BrandInput extends Omit<Brand, 'id'> {
  id?: string,
}

export interface Product {
  id: string,
  title: string,
  price: string,
  checkoutUrl: string,
  gender: string,
  description: string,
  brand?: Brand,
  subCategories: Subcategory[],
  productImages: Image[],
  hidden: boolean,
}

export interface ProductInput extends Omit<Product, 'id' | 'subCategories'> {
  id?: string,
  subCategories?: string[],
  brandId?: string,
}

export interface Notification {
  id: string,
  title: string,
  content: string,
}

export interface NotificationInput {
  id?: string,
  title?: string,
  content?: string,
  scheduledAt?: string,
}

export interface Image {
  id?: string,
  url: string,
  orderBy: number
}

export interface SharedRegistry {
  id?: string,
  name: string,
  private: boolean,
  collaborators: any[],
  type: SharedRegistryType,
  registryDate: string
  iconUrl?: string
}

export enum SharedRegistryType {
  Registry = "REGISTRY",
  Wishlist = "WISHLIST",
}

export interface CalendarEvent {
  id?: string,
  name: string,
  eventDate: string
  iconUrl?: string
}
