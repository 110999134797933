import React from "react";
import useFetch from "use-http";
import {IApiData, Product, ProductInput} from "../../../misc/ApiTypes";
import {useSnackbar} from "notistack";

const useProducts = (): IApiData<Product, ProductInput> => {
  const { get, post, put, del, response, loading, error } = useFetch();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (error?.message) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  }, [error]);

  const getList = async (page: number, pageSize: number, filters?: { [key: string]: string | undefined }) => {
    const params = new URLSearchParams();
    params.append('page', page?.toString());
    params.append('size', pageSize?.toString());

    if (filters) {
      Object.keys(filters).forEach(key => filters[key] && params.append(key, filters[key]!));
    }

    await get(`/product?` + params?.toString());
    if (response && response.status === 200) {
      return await response?.json();
    }
  }

  const getOne = async (id: string) => {
    await get(`/product/${id}`);
    if (response && response.status === 200) {
      return await response?.json();
    }
    return {};
  }

  const create = async (data: ProductInput) => {
    await post(`/product`, data)
    if (response?.ok) {
      enqueueSnackbar('Product added', {variant: "success"});
    }
    return response.json();
  }

  const update = async (data: ProductInput) => {
    await put(`/product/${data.id}`, data)
    if (response?.ok) {
      enqueueSnackbar('Changes saved', {variant: "success"});
    }
    return response.json();
  }

  const remove = async (id: string) => {
    await del(`/product/${id}`)
  }

  return {loading, create, update, remove, getList, getOne}
}

export default useProducts;

