import React from "react";
import {Box, Button, Card, Divider, IconButton, Typography} from "@mui/material";
import {DataGrid, GridValueGetterParams, GridColDef, GridRowId, GridCellParams} from "@mui/x-data-grid";
import {toCurrency} from "../../misc/utils";
import useProductListPageSize from "./hooks/usePrivateProductListPageSize";
import useProductListPage from "./hooks/usePrivateProductListPage";
import usePrivateProducts from "./hooks/usePrivateProducts";
import {useNavigate} from "react-router";
import {Product} from "../../misc/ApiTypes";
import moment from "moment";

const columns: GridColDef[] = [
  { field: 'title', headerName: 'Title', width: 400, disableColumnMenu: true, sortable: false},
  { field: 'price', headerName: 'Price', width: 150, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.price) {
        return toCurrency(params.row.price)
      }
      return ''
    }
  },
  { field: 'createdAt', headerName: 'Created at', sortable: false, width: 200, disableColumnMenu: true,
    valueGetter: (params: GridCellParams) => moment(params.row.createdAt).format('L H:mm:ss')
  },
  { field: 'checkoutUrl', headerName: 'Checkout Url', width: 400, disableColumnMenu: true, sortable: false,
    renderCell: (params: GridCellParams) => <a href={params.row.checkoutUrl}>{params.row.checkoutUrl}</a>
  },
];

export default function () {
  const [page, setPage] = useProductListPage();
  const [pageSize, setPageSize] = useProductListPageSize();
  const {loading, getList, remove} = usePrivateProducts();
  const navigate = useNavigate();
  const [total, setTotal] = React.useState(0);
  const [products, setProducts] = React.useState<Product[]>([]);

  React.useEffect(() => {
    load();
  }, [page, pageSize])

  const load = () => getList!(page, pageSize).then(response => {
    setProducts(response?.content ?? []);
    setTotal(response?.totalElements ?? 0);
  });

  return <div>
    <Box sx={{justifyContent: 'space-between', width: 1, display: 'flex'}}>
      <Typography variant={'h1'}><b>Private Products</b></Typography>
    </Box>
    <Divider sx={{my: 2}} />
    <Card>
      <Box sx={{height: '60vh'}}>
        <DataGrid rows={products}
                  density="compact"
                  paginationMode={"server"}
                  sortingMode={'server'}
                  columns={columns}
                  page={page}
                  loading={loading}
                  disableSelectionOnClick
                  onPageChange={(page) => {
                    setPage(page);
                  }}
                  rowsPerPageOptions={[20, 50, 100]}
                  onPageSizeChange={setPageSize}
                  pageSize={pageSize}
                  rowCount={total}
                  pagination
        />
      </Box>
    </Card>
  </div>
}
