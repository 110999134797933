import React from "react";
import {Box, Button} from "@mui/material";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import useFetch from "use-http";
import {useSnackbar} from "notistack";
import { HTML5Backend } from 'react-dnd-html5-backend'
import {DndProvider, useDrag, useDrop} from 'react-dnd'
import { Identifier, XYCoord } from 'dnd-core'
import * as _ from 'lodash';
import {Image} from "../../misc/ApiTypes";

interface ImageUploaderProps {
  list: Image[],
  uploadUrlSuffix: string,
  urlPrefix: string,
  fullPath?: boolean,
  onChange: (list: Image[]) => void,
  maxCount?: number,
  height?: number,
  width?: number,
}

const ImageUploader = (props: ImageUploaderProps) => {
  const [preview, setPreview] = React.useState<string>();
  const { post, response, loading, error } = useFetch();
  const {enqueueSnackbar} = useSnackbar();

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e?.target?.files as any;
    const uploaded: string[] = [];

    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);
      await post('/upload' + props.uploadUrlSuffix, formData);

      if (error) {
        enqueueSnackbar(error.message, {variant: "error"})
      } else {
        const fileData = await response.json();
        uploaded.push(props.fullPath ? fileData.cdnPath : fileData.path);
      }
    }

    props.onChange(mapImages([...props.list.map(i => i.url), ...uploaded]))
  }

  const onDeleteClick = React.useCallback(() => {
    if (preview) {
      const index = props.list.findIndex(i => i.url === preview)
      const newList = mapImages([...props.list.filter(i => i.url !== preview).map(i => i.url)])
      props.onChange(newList)
      setPreview(newList.length ? props.list[index - 1].url : undefined);
    }
  }, [preview, props.list])

  React.useEffect(() => {
    if (props.list.length && !preview) {
      setPreview(props.list[0].url)
    } else if (!props.list.length) {
      setPreview(undefined)
    }
  }, [props.list, preview])

  return <div>
    <input style={{display: 'none'}} id={'image-upload'} accept="image/png, image/gif, image/jpeg" multiple onChange={onChange} type="file" />
    <Box sx={{display: 'flex', gap: 1}}>
      <Box sx={{
        borderRadius: '4px',
        position: 'relative',
        height: props.height || 250,
        width: props.width || 360,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url("${preview ? prefixImgUrl(preview, props.urlPrefix, props.fullPath) : '/img/no-image-found.png'}")`
      }}>
        {preview && <Button sx={{position: 'absolute', top: 5, right: 5}}
                            onClick={onDeleteClick}
                            size={'small'}
                            variant={'contained'}
                            color={'error'}>Delete</Button>}
      </Box>
      <Box sx={{display: 'flex', gap: 1, flexDirection: 'column'}}>
        <label htmlFor={'image-upload'} style={{pointerEvents: props.maxCount && props.list.length >= props.maxCount ? 'none' : undefined}}>
          <Box sx={{
            borderRadius: '4px',
            height: 50,
            width: 72,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            border: '1px dashed #283345',
            opacity: props.maxCount && props.list.length >= props.maxCount ? .5 : 1,
          }}
               onClick={() => {}}>
            <AddCircleRoundedIcon sx={{color: '#283345'}} />
          </Box>
        </label>
        <DnDImageList urlPrefix={props.urlPrefix} list={props.list} onChange={props.onChange} onImageClick={url => setPreview(url)} fullPath={props.fullPath} />
      </Box>
    </Box>
  </div>
}

export default ImageUploader;

interface ImageListProps {
  urlPrefix: string,
  fullPath?: boolean,
  list: Image[],
  onChange: (list: Image[]) => void,
  onImageClick: (url: string) => void
}

const DnDImageList = (props: ImageListProps) => {
  return <DndProvider backend={HTML5Backend}>
    <ImageList {...props} />
  </DndProvider>
}

const ImageList = (props: ImageListProps) => {
  const [innerList, setInnerList] = React.useState<Image[]>([]);

  React.useEffect(() => {
    if (!_.isEqual(props.list, innerList)) {
      setInnerList(props.list)
    }
  }, [props.list])

  const moveCard = React.useCallback((dragIndex: number, hoverIndex: number) => {
    setInnerList(prev => {
      const arr = [...prev];
      arr.splice(dragIndex, 1);
      arr.splice(hoverIndex, 0, prev[dragIndex]);
      return arr;
    })
  }, [props.list])

  const onDrop = React.useCallback(() => {
    props.onChange(mapImages([...innerList.map(i => i.url)]));
  }, [innerList])

  return <>
    {innerList.map((img, index) => <ImageCard key={img.url}
                                              id={img.url}
                                              index={index}
                                              url={prefixImgUrl(img.url, props.urlPrefix, props.fullPath)}
                                              moveCard={moveCard}
                                              onDrop={onDrop}
                                              onClick={() => props.onImageClick(img.url)} />)}
  </>
}

interface DragItem {
  index: number,
  id: string,
}

interface ImageCardProps extends DragItem {
  url: string,
  onClick: () => void,
  moveCard: (dragIndex: number, hoverIndex: number) => void,
  onDrop: (item: DragItem) => void,
}

const ImageCard = (props: ImageCardProps) => {

  console.log(props.url);
  const ref = React.useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
    >({
    accept: 'image',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = props.index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'image',
    item: () => {
      return { id: props.id, index: props.index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      props.onDrop(item);
    }
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return <Box ref={ref} data-handler-id={handlerId} sx={{
    borderRadius: '4px',
    opacity,
    height: 50,
    width: 72,
    cursor: 'pointer',
    boxShadow: '2px 2px 0px rgb(0 0 0 / 15%)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url("${props.url}")`
  }}
              onClick={props.onClick} />
}

const prefixImgUrl = (url: string, urlPrefix: string, fullPath?: boolean) => fullPath ? url : process.env.REACT_APP_CDN + urlPrefix + url;
const mapImages = (data: string[]): Image[] => data.map((img, index) => ({url: img, orderBy: index + 1}))