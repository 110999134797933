import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider, CachePolicies } from 'use-http'
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from "@mui/material";
import {AppTheme} from "./misc/AppTheme";
import {RouterProvider} from "react-router-dom";
import {SnackbarProvider} from 'notistack';
import {router} from "./router";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={AppTheme}>
      <SnackbarProvider preventDuplicate classes={{
        variantSuccess: 'success-snack'
      }} anchorOrigin={{horizontal: 'center', vertical: 'bottom'}} autoHideDuration={6000} maxSnack={3}>
        <Provider url={process.env.REACT_APP_REST_API + '/api'} options={{
            cachePolicy: CachePolicies.NO_CACHE,
            interceptors: {
                request: async ({ options, url, path, route }: any) => {
                    options.headers.Authorization = 'Basic ' + localStorage.getItem('token');
                    return options
                },
            }
        }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <RouterProvider router={router} />
          </LocalizationProvider>
        </Provider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
