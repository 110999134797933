import React from "react";
import useFetch from "use-http";
import {IApiData, CalendarEvent} from "../../../misc/ApiTypes";
import {useSnackbar} from "notistack";

const useCalendarEvents = (): IApiData<CalendarEvent, CalendarEvent> => {
  const { get, post, put, del, response, loading, error } = useFetch();
  const { enqueueSnackbar } = useSnackbar();
  const baseUrl = '/shared_calendar_event'

  React.useEffect(() => {
    if (error?.message) {
      enqueueSnackbar(error?.message, {variant: "error"});
    }
  }, [error])

  const getList = async (page: number, pageSize: number) => {
    await get(`${baseUrl}?page=${page}&size=${pageSize}`);
    if (response && response.status === 200) {
      return await response?.json();
    }
  }

  const getOne = async (id: string) => {
    await get(`${baseUrl}/${id}`);
    return response.json();
  }

  const create = async (data: CalendarEvent) => {
    await post(`${baseUrl}`, data)
    if (response?.ok) {
      enqueueSnackbar('Event added', {variant: "success"});
    }
    return response.json();
  }

  const update = async (data: CalendarEvent) => {
    await put(`${baseUrl}/${data.id}`, data)
    if (response?.ok) {
      enqueueSnackbar('Changes saved', {variant: "success"});
    }
    return response.json();
  }

  const remove = async (id: string) => {
    await del(`${baseUrl}/${id}`)
  }

  return {loading, create, update, remove, getList, getOne}
}

export default useCalendarEvents;

