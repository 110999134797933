import React, {Dispatch, SetStateAction} from "react";

export default function<S>(sessionStorageKey: string, initialState: S): [S, Dispatch<SetStateAction<S>>] {

  const computedInitialState = React.useMemo<S>(() => {
    let data: S | undefined | null;
    try {
      const jsonStr = sessionStorage.getItem(sessionStorageKey);
      if (!!jsonStr && typeof jsonStr === 'string' && jsonStr !== 'undefined') {
        data = JSON.parse(jsonStr) as S
      }
    } catch (e) {
      console.error(e)
    }
    return data ? data : initialState;
  }, []);

  const [state, setState] = React.useState<S>(computedInitialState);

  const setStateWrapper = React.useCallback((value: SetStateAction<S>) => {
    try {
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(value));
    } catch (e) {
      console.error(e)
    }
    setState(value)
  }, [setState]);

  return [state, setStateWrapper]
}
