import React from "react";
import {Box, Card, Divider, Typography} from "@mui/material";
import ImageUploader from "../../shared/components/ImageUploader";
import {Image} from "../../misc/ApiTypes";
import useWishlistIcons from "./hooks/useWishlistIcons";
import * as _ from 'lodash';

export default function () {
  const [images, setImages] = React.useState<Image[]>([]);
  const {get, remove, upload, reorder} = useWishlistIcons();

  React.useEffect(() => {
    load();
  }, [])

  const load = () => get().then(imgs => setImages(_.orderBy(imgs ?? [], 'orderBy')));

  const onImagesChange = React.useCallback(async (data: Image[]) => {
    const toAdd = _.differenceBy(data, images, 'url')[0];
    if (toAdd) {
      await upload(toAdd).then(res => {
        data.forEach(i => {
          if ( i.url === res.url) {
            i.id = res.id;
          } else {
            i.id = images.find(im => im.url === i.url)?.id;
          }
        })
        return reorder(data.map(i => i.id as string))
      }).then(load);
    }
    const toRemove = _.differenceBy(images, data, 'url')[0];
    if (toRemove?.id) {
      await remove(toRemove.id).then(load).then(() => reorder(images.filter(i => i.id !== toRemove.id).map(i => i.id as string)));
    }
    if (!toRemove?.id && !toAdd) {
      data.forEach(i => {
        i.id = images.find(im => im.url === i.url)?.id;
      })
       reorder(data.map(i => i.id as string)).then(load)
    }
  }, [images])

  return <div>
    <Box sx={{justifyContent: 'space-between', width: 1, display: 'flex'}}>
      <Typography variant={'h1'}><b>Wishlist icons</b></Typography>
    </Box>
    <Divider sx={{my: 2}} />
    <Card sx={{p: 1}}>
      <ImageUploader width={100} height={100} urlPrefix={'/wish_list_icon/'} uploadUrlSuffix={'/WISH_LIST_ICON'} list={images} onChange={onImagesChange} />
    </Card>
  </div>
}
