import React from "react";
import {Box, Button, Card, Divider, Typography} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import {CategoryInput, Image, SubcategoryInput} from "../../misc/ApiTypes";
import useCategories from "./hooks/useCategories";
import CategoryForm from "./components/CategoryForm";
import useSubcategories from "./hooks/useSubcategories";
import * as _ from "lodash";
import SubcategoryList from "./components/SubcategoryList";
import ImageUploader from "../../shared/components/ImageUploader";

export default function () {
  const {id} = useParams();
  const navigate = useNavigate();
  const {create, update, getOne} = useCategories();
  const [initial, setInitial] = React.useState<CategoryInput>();
  const [image, setImage] = React.useState<string>();
  const [items, setItems] = React.useState<SubcategoryInput[]>([]);
  const {getByCategory, remove, create: createItem, update: updateItem} = useSubcategories();

  const onDeleteItemClick = React.useCallback((item: SubcategoryInput) => {
    if (item.id) {
      remove(item.id).then(() => setItems(prev => prev.filter(i => i.id !== item.id)));
    } else {
      setItems(prev => prev.filter(i => !_.isEqual(i, item)))
    }
  }, [id]);

  const onItemChange = React.useCallback((prev: SubcategoryInput, next: SubcategoryInput) => {
    if (!next.name) {
      setItems(prevValue => prevValue.filter(i => i.name))
    } else {
      setItems(prevValue => _.orderBy([...prevValue.filter(i => !_.isEqual(i, prev)), next], 'name'))
    }
  }, [items]);

  React.useEffect(() => {
    if (id) {
      getOne(id).then(data => {
        setInitial(data);
        setImage(data.imageUrl);
      });
      getByCategory(id).then(data => setItems(data.map(i => ({...i, category: i.category.id}))))
    }
  }, [id])

  const goToCategories = () => navigate('/categories')

  const onSubmit = React.useCallback(async (data: CategoryInput) => {
    if (id) {
      await update({...data, id, imageUrl: image});
      for (const item of items) {
        if (item.id) {
          await updateItem({...item})
        } else {
          await createItem(item)
        }
      }
    } else {
      const created = await create({...data, imageUrl: image});
      for (const item of items) {
        await createItem({name: item.name, category: created.id})
      }
    }
    goToCategories();
  }, [id, create, update, items, image])

  const onImagesChange = React.useCallback(async (data: Image[]) => {
    if (initial) {
      await update({...initial, imageUrl: data[0]?.url});
    }
    setImage(data[0]?.url)
  }, [update, initial, image])


  return <div>
    <Box sx={{justifyContent: 'space-between', width: 1, display: 'flex'}}>
      <Typography variant={'h1'}><b>{id ? 'Update' : 'Add'} category</b></Typography>
    </Box>
    <Divider sx={{my: 2}} />
    <Card sx={{p: 4, display: 'flex', gap: 5, flexDirection: 'row'}}>
      <CategoryForm data={initial} onSubmit={onSubmit} onCancel={goToCategories} loading={items.some(i => !i.name)}>
        <Button disabled={items.some(i => !i.name)}
                onClick={() => setItems(prev => [...prev, {name: '', category: id || '', id: ''}])}>Add subcategory</Button>
        <SubcategoryList items={items} onChange={onItemChange} onDeleteClick={onDeleteItemClick} />
      </CategoryForm>
      <ImageUploader urlPrefix={'/category/'} uploadUrlSuffix={'/CATEGORY'} list={image ? [{url: image, orderBy: 0}] : []} onChange={onImagesChange} maxCount={1} />
    </Card>
  </div>
}
