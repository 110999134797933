import React from "react";
import useFetch from "use-http";
import {Image} from "../../../misc/ApiTypes";
import {useSnackbar} from "notistack";

const useWishlistIcons = () => {
  const { get: httpGet, post, put, del, response, loading, error } = useFetch();
  const {enqueueSnackbar} = useSnackbar();

  React.useEffect(() => {
    if (error?.message) {
      enqueueSnackbar(error?.message, {variant: "error"});
    }
  }, [error])

  const get = async () => {
    await httpGet(`/wish_list_icon`);
    if (response && response.status === 200) {
      return await response?.json();
    }
  }

  const upload = async (data: Image) => {
    await post(`/wish_list_icon`, data)
    if (response?.ok) {
      enqueueSnackbar('Icon added', {variant: "success"});
    }
    return response.json();
  }

  const remove = async (id: string) => {
    await del(`/wish_list_icon/${id}`)
  }

  const reorder = async (ids: string[]) => {
    await put(`/wish_list_icon/reorder`, ids);
  }

  return {loading, get, upload, remove, reorder}
}

export default useWishlistIcons;

