import React from "react";
import {Box, Button, Card, Divider, IconButton, Typography} from "@mui/material";
import {DataGrid, GridValueGetterParams, GridColDef, GridRowId, GridCellParams} from "@mui/x-data-grid";
import {toCurrency} from "../../misc/utils";
import useSharedRegistryListPageSize from "./hooks/useSharedRegistryListPageSize";
import useSharedRegistryListPage from "./hooks/useSharedRegistryListPage";
import useSharedRegistry from "./hooks/useSharedRegistry";
import {useNavigate} from "react-router";
import {SharedRegistry} from "../../misc/ApiTypes";
import ClearIcon from '@mui/icons-material/Clear';

interface ICallbacks {
  deleteRow: (id: string) => void
}

const columns: (callbacks: ICallbacks) => GridColDef[] = (callbacks: ICallbacks) => [
  { field: 'name', headerName: 'Name', width: 200, disableColumnMenu: true, sortable: false},
  {
    field: 'private', headerName: 'Private', width: 150, disableColumnMenu: true, sortable: false,
  },
  { field: 'registryDate', headerName: 'Registry date', width: 200, disableColumnMenu: true, sortable: false },

  { field: 'id', headerName: '', sortable: false, disableColumnMenu: true, disableClickEventBubbling: true,
    renderCell: (params: GridCellParams) => <IconButton onClick={event => {
      event.stopPropagation();
      callbacks.deleteRow(params.row.id)
    }}><ClearIcon style={{color: 'red'}}  /></IconButton>
  },
];

export default function () {
  const [page, setPage] = useSharedRegistryListPage();
  const [pageSize, setPageSize] = useSharedRegistryListPageSize();
  const {loading, getList, remove} = useSharedRegistry();
  const navigate = useNavigate();
  const [total, setTotal] = React.useState(0);
  const [sharedRegistries, setSharedRegistries] = React.useState<SharedRegistry[]>([]);

  React.useEffect(() => {
    load()
  }, [page, pageSize])

  const load = () => getList(page, pageSize).then(response => {
    setSharedRegistries(response.content);
    setTotal(response.totalElements)
  });

  const callbacks: ICallbacks = {
    deleteRow: id => remove(id).then(load),
  }

  return <div>
    <Box sx={{justifyContent: 'space-between', width: 1, display: 'flex'}}>
      <Typography variant={'h1'}><b>Shared Registries</b></Typography>
      <Button variant={'contained'} color={'primary'} onClick={() => navigate('/shared_registry')}>Add shared registry</Button>
    </Box>
    <Divider sx={{my: 2}} />
    <Card>
      <Box sx={{height: '60vh'}}>
        <DataGrid rows={sharedRegistries}
                  density="compact"
                  paginationMode={"server"}
                  sortingMode={'server'}
                  columns={columns(callbacks)}
                  page={page}
                  loading={loading}
                  disableSelectionOnClick
                  onPageChange={(page) => {
                    setPage(page);
                  }}
                  onRowClick={(param, event) => {
                    navigate('/shared_registry/' + param.row.id)
                  }}
                  rowsPerPageOptions={[20, 50, 100]}
                  onPageSizeChange={setPageSize}
                  pageSize={pageSize}
                  rowCount={total}
                  pagination
        />
      </Box>
    </Card>
  </div>
}
