import {Box, Button, Card, Divider, IconButton, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {DataGrid, GridCellParams, GridColDef} from "@mui/x-data-grid";
import useBroadcastListPage from "./hooks/useBroadcastListPage";
import useBroadcastListPageSize from "./hooks/useBroadcastListPageSize";
import useBroadcast from "./hooks/useBroadcast";
import useShuffleProducts from "../products/hooks/useShuffleProducts";
import {useNavigate} from "react-router";
import {Notification} from "../../misc/ApiTypes";
import ClearIcon from "@mui/icons-material/Clear";
import TitleFilter from "../products/components/TitleFilter";
import BrandFilter from "../products/components/BrandFilter";

interface ICallbacks {
    deleteRow: (id: string) => void
}

const columns: (callbacks: ICallbacks) => GridColDef[] = (callbacks: ICallbacks) => [
    {field: 'title', headerName: 'Title', width: 200, disableColumnMenu: true, sortable: false},
    {field: 'content', headerName: 'Content', width: 150, disableColumnMenu: true, sortable: false},
    {field: 'sent', headerName: 'Status', width: 150, disableColumnMenu: true, sortable: false,  renderCell: (params: GridCellParams) => <p>{params.row.sent ? 'Sent' : 'Pending'}</p>},
    {
        field: 'id', headerName: '', sortable: false, disableColumnMenu: true, disableClickEventBubbling: true,
        renderCell: (params: GridCellParams) => <IconButton onClick={event => {
            event.stopPropagation();
            callbacks.deleteRow(params.row.id)
        }}><ClearIcon style={{color: 'red'}}/></IconButton>
    },
];

export default function () {
    const [page, setPage] = useBroadcastListPage();
    const [pageSize, setPageSize] = useBroadcastListPageSize();
    const {loading, getList, remove} = useBroadcast();
    const {loading: shuffleLoading, shuffle} = useShuffleProducts();
    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const [notifications, setNotifications] = useState<Notification[]>([]);

    useEffect(() => {
        load();
    }, [page, pageSize])

    const load = () => getList(page, pageSize,).then(response => {
        setNotifications(response?.content ?? []);
        setTotal(response?.totalElements ?? 0);
    });

    const callbacks: ICallbacks = {
        deleteRow: id => remove(id).then(load),
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center', width: '100%'}}>
            <Box sx={{justifyContent: 'space-between', width: 1, display: 'flex'}}>
                <Typography variant={'h1'}><b>Notifications</b></Typography>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Button variant={'contained'} color={'primary'} onClick={() => navigate('/notification')}>Add notification</Button>
                </Box>
            </Box>
            <Card sx={{width: '100%'}}>
                <Box sx={{height: '60vh', width: '100%'}}>
                    <DataGrid rows={notifications}
                              density="compact"
                              paginationMode={"server"}
                              sortingMode={'server'}
                              columns={columns(callbacks)}
                              page={page}
                              loading={loading || shuffleLoading}
                              disableSelectionOnClick
                              onPageChange={(page) => {
                                  setPage(page);
                              }}
                              onRowClick={(param, event) => {
                                  navigate('/notification/' + param.row.id)
                              }}
                              rowsPerPageOptions={[20, 50, 100]}
                              onPageSizeChange={setPageSize}
                              pageSize={pageSize}
                              rowCount={total}
                              pagination
                    />
                </Box>
            </Card>
        </Box>
    )
}
