import React from "react";
import {Box, Button, Card, Divider, Typography} from "@mui/material";
import SharedRegistryForm from "./components/SharedRegistryForm";
import {Image, SharedRegistry} from "../../misc/ApiTypes";
import { useParams, useNavigate } from "react-router-dom";
import useSharedRegistry from "./hooks/useSharedRegistry";
import ImageUploader from "../../shared/components/ImageUploader";

export default function () {
  const {id} = useParams();
  const navigate = useNavigate();
  const { create, update, getOne, loading } = useSharedRegistry();
  const [initial, setInitial] = React.useState<SharedRegistry>();
  const [image, setImage] = React.useState<string>();

  React.useEffect(() => {
    if (id) {
      getOne(id).then(data => {
        setInitial(data);
        setImage(data.iconUrl);
      });
    }
  }, [id])

  const goToList = () => navigate('/shared-registries')

  const onSubmit = React.useCallback((data: SharedRegistry) => {
    if (id) {
      return update({ ...data, id }).then(goToList);
    } else {
      return create(data).then(goToList)
    }
  }, [id, create, update, image])

  const onImagesChange = React.useCallback(async (data: Image[]) => {
    if (initial) {
      await update({...initial, iconUrl: data[0]?.url});
    }
    setImage(data[0]?.url)
  }, [update, initial, image])

  return <div>
    <Box sx={{justifyContent: 'space-between', width: 1, display: 'flex'}}>
      <Typography variant={'h1'}><b>{id ? 'Update' : 'Add'} shared registry</b></Typography>
    </Box>
    <Divider sx={{my: 2}} />
    <Card sx={{p: 4, display: 'flex', gap: 5, flexDirection: 'row'}}>
      <SharedRegistryForm data={initial} onSubmit={onSubmit} onCancel={goToList} loading={loading} />
      <ImageUploader width={100} height={100} maxCount={1} urlPrefix={'/wish_list_icon/'} uploadUrlSuffix={'/WISH_LIST_ICON'} list={image ? [{url: image, orderBy: 0}] : []} onChange={onImagesChange} />
    </Card>
  </div>
}