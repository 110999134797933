import {Box, Button, Card, Divider, TextField, Typography, Input, FormControl, InputLabel} from "@mui/material";

import React, {useState} from "react";
import {NotificationInput} from "../../misc/ApiTypes";
import useBroadcast from "./hooks/useBroadcast";
import {useNavigate, useParams} from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import {useFormik} from "formik";
import * as yup from "yup";
import {Label} from "@mui/icons-material";

const validationSchema = yup.object({
    title: yup
        .string()
        .required('Title is required'),
    content: yup
        .string()
        .required('Title is required'),
    date: yup
        .string(),
});

export default function AddNotificationForm() {
    const {id} = useParams();
    const navigate = useNavigate();

    const [initial, setInitial] = useState<NotificationInput>();

    const {create, update, getOneById} = useBroadcast();

    const onSubmit = React.useCallback((data: NotificationInput) => {
        if (id) {
            return update({...data, id}).then(goToNotifications);
        } else {
            return create({...data}).then(goToNotifications)
        }
    }, [id, create, update])

    const formik = useFormik({
        initialValues: {
            title: initial?.title || '',
            content: initial?.content || '',
            scheduledAt: initial?.scheduledAt || '',
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            if(values.scheduledAt) {
                onSubmit({
                    ...values
                });
            } else {
                onSubmit({
                    title: values.title,
                    content: values.content,
                });
            }
        },
    });

    React.useEffect(() => {
        if (id) {
            if (getOneById) {
                getOneById(id).then(data => {
                    if (data?.content?.[0]) {
                        setInitial(data.content?.[0]);
                    }
                });
            }
        }
    }, [id])


    const goToNotifications = () => navigate('/notifications')

    return (
        <div>
            <Box sx={{justifyContent: 'space-between', width: 1, display: 'flex'}}>
                <Typography variant={'h1'}><b>{id ? 'Update' : 'Add'} notification</b></Typography>
            </Box>
            <Divider sx={{my: 2}}/>
            <Card sx={{p: 4, display: 'flex', gap: 5, flexDirection: 'row'}}>
                <Box sx={{width: 400}} component={'form'} onSubmit={formik.handleSubmit} className={'mt-15'}>
                    <Grid container spacing={4}>
                        <Grid xs={12}>
                            <TextField
                                name="title"
                                size='medium'
                                label="Notification title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title}
                                sx={{width: '100%'}}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <label>Sending date</label>
                            <TextField
                                type='date'
                                name='scheduledAt'
                                sx={{width: '100%'}}
                                value={formik.values.scheduledAt}
                                onChange={formik.handleChange}
                                error={formik.touched.scheduledAt && Boolean(formik.errors.scheduledAt)}
                                helperText={formik.touched.scheduledAt && formik.errors.scheduledAt}
                                inputProps={{min: new Date().toISOString().split("T")[0]}}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <TextField
                                name="content"
                                sx={{width: '100%'}}
                                label="Notification text"
                                value={formik.values.content}
                                onChange={formik.handleChange}
                                error={formik.touched.content && Boolean(formik.errors.content)}
                                helperText={formik.touched.content && formik.errors.content}
                                minRows={4}
                                multiline={true}
                            />
                        </Grid>
                        <Grid container alignItems={"center"} direction={'row'}>
                            <Grid>
                                <Button variant={'outlined'} onClick={goToNotifications}>CANCEL</Button>
                            </Grid>
                            <Grid>
                                <Button variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </div>

    )
    // return (
    //     <Box sx={{display: 'flex', flexDirection: 'column', gap: 1.5, alignItems: 'center', width: '100%', maxWidth: '500px'}}>
    //         <TextField
    //             InputProps={{
    //                 endAdornment:
    //                     <InputAdornment position="end">
    //                         {!!title && <CloseIcon cursor={'pointer'} onClick={() => {
    //                             setTitle('');
    //                         }} fontSize={'small'} color={'inherit'}/>}
    //                     </InputAdornment>,
    //             }}
    //             size='medium'
    //             label="Notification title"
    //             value={title}
    //             onChange={event => {
    //                 setTitle(event.target.value as string);
    //             }}
    //             sx={{width: '100%'}}
    //         />
    //         <TextField
    //             sx={{width: '100%'}}
    //             InputProps={{
    //                 endAdornment:
    //                     <InputAdornment position="end">
    //                         {!!content && <CloseIcon cursor={'pointer'} onClick={() => {
    //                             setContent('');
    //                         }} fontSize={'small'} color={'inherit'}/>}
    //                     </InputAdornment>,
    //             }}
    //             label="Notification text"
    //             value={content}
    //             onChange={event => {
    //                 setContent(event.target.value as string);
    //             }}
    //             minRows={4}
    //             multiline={true}
    //         />
    //         <Button sx={{width: '100%'}} variant={'contained'} color={'primary'} onClick={() => {}}>Create notification</Button>
    //     </Box>
    // )
}
