import React from "react";
import * as yup from 'yup';
import {useFormik} from "formik";
import {
  Box,
  Button, Checkbox, Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputAdornment,
  InputLabel, ListItemText, ListSubheader,
  MenuItem,
  Select, SelectChangeEvent,
  TextField
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {Brand, Category, Product, ProductInput, Subcategory} from "../../../misc/ApiTypes";
import useBrands from "../../brands/hooks/useBrands";
import useCategories from "../../categories/hooks/useCategories";
import * as _ from "lodash";


const validationSchema = yup.object({
  title: yup
    .string()
    .required('Title is required'),
  price: yup
    .number()
    .required('Price is required'),
  gender: yup
    .string()
    .required('Gender is required'),
  description: yup
    .string()
    .required('Description is required'),
  brandId: yup
    .string(),
  subCategories: yup
    .array()
    .required('Categories are required'),
  checkoutUrl: yup
    .string()
    .required('Checkout url is required'),
  hidden: yup
    .string()
});

type ProductFormValues = Omit<ProductInput, 'productImages'>

interface ProductFormProps {
  data?: ProductFormValues,
  onCheckoutUrlChange: (url: string) => void,
  onSubmit: (values: ProductFormValues) => Promise<any>,
  onCancel: () => void,
  loading: boolean,
}

const ProductForm = (props: ProductFormProps) => {
  const {getList} = useBrands();
  const {getTree} = useCategories();
  const [brands, setBrands] = React.useState<Brand[]>([]);
  const [categories, setCategories] = React.useState<Array<Category | Subcategory>>([]);
  const formik = useFormik({
    initialValues: {
      title: props.data?.title || '',
      price: props.data?.price || '',
      gender: props.data?.gender || '',
      description: props.data?.description || '',
      brandId: props.data?.brand?.id || '',
      subCategories: props.data?.subCategories || [],
      checkoutUrl: props.data?.checkoutUrl || '',
      hidden: props.data?.hidden || false,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      props.onSubmit({
        ...values,
      });
    },
  });

  React.useEffect(() => {
    getList(0, 9999).then(res => setBrands(res.content))
    getTree().then(response => {
      setCategories(_.orderBy(response, 'name').reduce((previousValue, currentValue: Category) => {
        return [...previousValue, currentValue, ...(currentValue.subCategories || [])]
      }, [] as Array<Category | Subcategory>))
    });
  }, [])

  React.useEffect(() => {
    props.onCheckoutUrlChange(formik.values.checkoutUrl);
  }, [formik.values.checkoutUrl])

  return (
    <Box sx={{width: 400}} component={'form'} onSubmit={formik.handleSubmit} className={'mt-15'}>
      <Grid container spacing={4}>
        <Grid xs={12}>
          <TextField
            fullWidth
            name="title"
            label="Title*"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            fullWidth
            name="description"
            label="Description*"
            multiline
            rows={4}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            fullWidth
            name="checkoutUrl"
            label="Checkout url*"
            value={formik.values.checkoutUrl}
            onChange={formik.handleChange}
            error={formik.touched.checkoutUrl && Boolean(formik.errors.checkoutUrl)}
            helperText={formik.touched.checkoutUrl && formik.errors.checkoutUrl}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            fullWidth
            name="price"
            label="Price*"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            type={'number'}
            value={formik.values.price}
            onChange={formik.handleChange}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helperText={formik.touched.price && formik.errors.price}
          />
        </Grid>
        <Grid xs={12}>
          <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
            <InputLabel sx={{color: (formik.touched.gender && formik.errors.gender) ? '#d32f2f' : undefined}} id="select-label">Gender</InputLabel>
            <Select
              labelId="select-label"
              label={'Gender*'}
              value={formik.values.gender}
              onChange={event => {
                formik.setFieldValue("gender", event.target.value as string);
              }}
              error={formik.touched.gender && Boolean(formik.errors.gender)}
            >
              <MenuItem value={'MALE'}>Male</MenuItem>
              <MenuItem value={'FEMALE'}>Female</MenuItem>
              <MenuItem value={'UNISEX'}>Unisex</MenuItem>
            </Select>
            {(formik.touched.gender && formik.errors.gender) && <FormHelperText sx={{color: '#d32f2f'}}>{formik.errors.gender}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
            <InputLabel sx={{color: (formik.touched.subCategories && formik.errors.subCategories) ? '#d32f2f' : undefined}} id="category-label">Categories*</InputLabel>
            <Select
              labelId="category-label"
              multiple
              label={'Categories*'}
              value={formik.values.subCategories}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={categories.find(i => i.id === value)?.name} />
                  ))}
                </Box>
              )}
              onChange={event => {
                formik.setFieldValue("subCategories", event.target.value as string);
              }}
              error={formik.touched.subCategories && Boolean(formik.errors.subCategories)}
            >
              {categories.map(cat => typeof (cat as Category)['hidden'] !== 'undefined' ?
                <ListSubheader sx={{backgroundColor: '#dddddd', color: '#3a4244', lineHeight: '38px'}} key={cat.id}>{cat.name}</ListSubheader> :
                <MenuItem key={cat.id} value={cat.id}>
                  <Checkbox size={'small'} disableRipple checked={formik.values.subCategories.indexOf(cat.id) > -1} />
                  <ListItemText primary={cat.name} />
                </MenuItem>)}
            </Select>
            {(formik.touched.subCategories && formik.errors.subCategories) && <FormHelperText sx={{color: '#d32f2f'}}>{formik.errors.subCategories}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
            <InputLabel sx={{color: (formik.touched.brandId && formik.errors.brandId) ? '#d32f2f' : undefined}} id="brandId-label">Brand</InputLabel>
            <Select
              labelId="brandId-label"
              label={'Brand'}
              value={formik.values.brandId}
              onChange={event => {
                formik.setFieldValue("brandId", event.target.value as string);
              }}
              error={formik.touched.brandId && Boolean(formik.errors.brandId)}
            >
              {brands.map(brand => <MenuItem key={brand.id} value={brand.id}>{brand.name}</MenuItem>)}
            </Select>
            {(formik.touched.brandId && formik.errors.brandId) && <FormHelperText sx={{color: '#d32f2f'}}>{formik.errors.brandId}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid xs={6}>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={formik.values.hidden}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue('hidden', event.target.checked);
              }} />} label="Hidden" />
          </FormGroup>
        </Grid>
        <Grid container alignItems={"center"} direction={'row'}>
          <Grid>
            <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
          </Grid>
          <Grid>
            <Button disabled={props.loading} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductForm;
