import React from "react";
import {Box, Button, Card, Divider, Typography} from "@mui/material";
import ProductForm from "./components/ProductForm";
import useProducts from "./hooks/useProducts";
import {Image, ProductInput} from "../../misc/ApiTypes";
import { useParams, useNavigate } from "react-router-dom";
import ImageUploader from "../../shared/components/ImageUploader";

export default function () {
  const {id} = useParams();
  const navigate = useNavigate();
  const {create, update, getOne} = useProducts();
  const [initial, setInitial] = React.useState<ProductInput>();
  const [checkoutUrl, setCheckoutUrl] = React.useState<string>();
  const [productImages, setProductImages] = React.useState<Image[]>([]);

  React.useEffect(() => {
    if (id) {
      getOne(id).then(data => {
        setInitial({...data, subCategories: data.subCategories.map(i => i.id)});
        setProductImages(data.productImages);
      });
    }
  }, [id])

  const goToProducts = () => navigate('/products')

  const onSubmit = React.useCallback((data: Omit<ProductInput, 'productImages'>) => {
    if (id) {
      return update({...data, id, productImages}).then(goToProducts);
    } else {
      return create({...data, productImages}).then(goToProducts)
    }
  }, [id, create, update, productImages])

  const onImagesChange = React.useCallback(async (data: Image[]) => {
    if (initial) {
      await update({...initial, productImages: data, brandId: initial.brand?.id, brand: undefined});
    }
    setProductImages(data)
  }, [update, initial, productImages])

  return <div>
    <Box sx={{justifyContent: 'space-between', width: 1, display: 'flex'}}>
      <Typography variant={'h1'}><b>{id ? 'Update' : 'Add'} product</b></Typography>
    </Box>
    <Divider sx={{my: 2}} />
    <Card sx={{p: 4, display: 'flex', gap: 5, flexDirection: 'row'}}>
      <ProductForm data={initial} onCheckoutUrlChange={setCheckoutUrl} onSubmit={onSubmit} onCancel={goToProducts} loading={false} />
      <div>
        <ImageUploader urlPrefix={'/image/'} uploadUrlSuffix={'/PRODUCT'} list={productImages} onChange={onImagesChange} />
        {checkoutUrl && <Box sx={{mt: 2, width: 1, height: 'calc(100% - 250px)'}}>
          <iframe src={checkoutUrl}
                  width={'100%'}
                  height={'100%'}
                  style={{width: '100%!important', height: '100%!important'}}></iframe>
        </Box>}
      </div>
    </Card>
  </div>
}