import React from "react";
import * as yup from 'yup';
import {useFormik} from "formik";
import {
  Box,
  Button, Checkbox, FormControlLabel, FormGroup,
  TextField
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {CalendarEvent} from "../../../misc/ApiTypes";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import moment from "moment";


const validationSchema = yup.object({
  name: yup
    .string()
    .required('Name is required'),
  eventDate: yup
    .string()
    .typeError('Date is required')
    .test('eventDate', 'Date is not valid', value => {
      const val = moment(value);
      return val.isValid() && val.isAfter(moment())
    })
    .required('Date is required'),
});

interface CalendarEventFormProps {
  data?: CalendarEvent,
  onSubmit: (values: CalendarEvent) => Promise<any>,
  onCancel: () => void,
  loading: boolean,
}

const CalendarEventForm = (props: CalendarEventFormProps) => {
  const formik = useFormik({
    initialValues: {
      name: props.data?.name || '',
      eventDate: props.data?.eventDate ? moment(props.data?.eventDate) : null,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      props.onSubmit({
        ...values,
        eventDate: moment(values.eventDate).format('YYYY-MM-DD')
      });
    },
  });

  return (
    <Box sx={{width: 400}} component={'form'} onSubmit={formik.handleSubmit} className={'mt-15'}>
      <Grid container spacing={4}>
        <Grid xs={12}>
          <TextField
            fullWidth
            name="name"
            label="Name*"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid xs={6}>
          <DesktopDatePicker
            label="Date*"
            inputFormat="MM/DD/YYYY"
            disablePast
            value={formik.values.eventDate}
            onChange={(value: any) => formik.setFieldValue('eventDate', value)}
            renderInput={(params: any) => <TextField
              {...params}
              fullWidth
              name="registryDate"
              error={formik.touched.eventDate && Boolean(formik.errors.eventDate)}
              helperText={formik.touched.eventDate && formik.errors.eventDate}
            />}
          />
        </Grid>
        <Grid container alignItems={"center"} direction={'row'}>
          <Grid>
            <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
          </Grid>
          <Grid>
            <Button disabled={props.loading} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CalendarEventForm;
