import React, {PropsWithChildren} from "react";
import * as yup from 'yup';
import {useFormik} from "formik";
import {
  Box,
  Button, Stack,
  TextField
} from "@mui/material";
import {Subcategory} from "../../../misc/ApiTypes";


const validationSchema = yup.object({
  name: yup
    .string()
    .required('Name is required'),
});

type SubcategoryFormValues = Omit<Subcategory, 'id' | 'category'>

interface SubcategoryFormProps {
  data?: SubcategoryFormValues,
  onSubmit: (values: SubcategoryFormValues) => Promise<any>,
  onCancel: () => void,
  loading: boolean,
}

const SubcategoryForm = (props: SubcategoryFormProps) => {
  const formik = useFormik({
    initialValues: {
      name: props.data?.name || '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      props.onSubmit({
        ...values,
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction={'row'}  spacing={2}>
        <TextField
          fullWidth
          name="name"
          label="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <Box pt={1}>
          <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
        </Box>
        <Box pt={1}>
          <Button disabled={props.loading} variant={'contained'} color={'primary'} onClick={() => props.onSubmit({name: formik.values.name})}>SAVE</Button>
        </Box>
      </Stack>
    </form>
  );
};

export default SubcategoryForm;
