import React from "react";
import useFetch from "use-http";
import {IApiData, Product, ProductInput} from "../../../misc/ApiTypes";
import {useSnackbar} from "notistack";

const useShuffleProducts = () => {

  const { post, response, loading, error } = useFetch();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (error?.message) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  }, [error]);

  const shuffle = async () => {
    await post(`/product_shuffle`)
    if (response?.ok) {
      enqueueSnackbar('Success', {variant: "success"});
    }
  }


  return {loading, response, shuffle}
}

export default useShuffleProducts;

