import { InputAdornment, TextField } from '@mui/material';
import { useDebouncedEffect } from '../../../shared/hooks/useDebouncedEffect';
import CloseIcon from '@mui/icons-material/Close';
import useProductListTerm from '../hooks/useProductListTerm';
import React from 'react';

const TitleFilter = ({ onChange, initial }: { initial: string, onChange: (value: string) => void; }) => {
  const [term, setTerm] = React.useState<string>(initial);

  useDebouncedEffect(() => {
    onChange(term);
  }, 400, [term])

  return <TextField
    InputProps={{
      endAdornment:
        <InputAdornment position="end">
          {!!term && <CloseIcon cursor={'pointer'} onClick={() => {
            setTerm('');
          }} fontSize={'small'} color={'inherit'} />}
        </InputAdornment>,
    }}
    size='small'
    label="Search by title"
    value={term}
    onChange={event => {
      setTerm(event.target.value as string);
    }}
  />
}

export default TitleFilter;