import React, {PropsWithChildren} from "react";
import * as yup from 'yup';
import {useFormik} from "formik";
import {
  Box,
  Button, Checkbox, FormControl, FormControlLabel, FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText  
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {Category, CategoryType} from "../../../misc/ApiTypes";


const validationSchema = yup.object({
  name: yup
    .string()
    .required('Name is required'),
  type: yup
    .string()
    .required('Type is required'),
  hidden: yup
    .boolean(),
});

type CategoryFormValues = Omit<Category, 'id'>

interface CategoryFormProps {
  data?: CategoryFormValues,
  onSubmit: (values: CategoryFormValues) => Promise<any>,
  onCancel: () => void,
  loading: boolean,
}

const CategoryForm = (props: PropsWithChildren<CategoryFormProps>) => {
  const formik = useFormik({
    initialValues: {
      name: props.data?.name || '',
      hidden: props.data?.hidden || false,
      type: props.data?.type || CategoryType.DEFAULT,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      props.onSubmit({
        ...values,
      });
    },
  });

  return (
    <Box sx={{width: 400}} component={'form'} onSubmit={formik.handleSubmit} className={'mt-15'}>
      <Grid container spacing={4}>
        <Grid xs={12}>
          <TextField
            fullWidth
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid xs={6}>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={formik.values.hidden}
                                                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                   formik.setFieldValue('hidden', event.target.checked);
                                                 }} />} label="Hidden" />
          </FormGroup>
        </Grid>
        <Grid xs={6}>
          <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
            <InputLabel sx={{color: (formik.touched.type && formik.errors.type) ? '#d32f2f' : undefined}} id="select-label">Type*</InputLabel>
            <Select
              labelId="select-label"
              label={'Type*'}
              value={formik.values.type}
              onChange={event => {
                formik.setFieldValue("type", event.target.value as string);
              }}
              error={formik.touched.type && Boolean(formik.errors.type)}
            >
              <MenuItem value={CategoryType.DEFAULT}>Default</MenuItem>
              <MenuItem value={CategoryType.SPECIAL}>Special</MenuItem>
              <MenuItem value={CategoryType.VERTICAL}>Vertical</MenuItem>
              <MenuItem value={CategoryType.OCCASION}>Shop by Occasion</MenuItem>
              <MenuItem value={CategoryType.THEMED}>Themed</MenuItem>
            </Select>
            {(formik.touched.type && formik.errors.type) && <FormHelperText sx={{color: '#d32f2f'}}>{formik.errors.type}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid xs={12}>
          {props.children}
        </Grid>
        <Grid container alignItems={"center"} direction={'row'}>
          <Grid>
            <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
          </Grid>
          <Grid>
            <Button disabled={props.loading} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CategoryForm;
