import React from "react";
import useFetch from "use-http";
import {IApiData, SharedRegistry} from "../../../misc/ApiTypes";
import {useSnackbar} from "notistack";

const useProducts = (): IApiData<SharedRegistry, SharedRegistry> => {
  const { get, post, put, del, response, loading, error } = useFetch();
  const { enqueueSnackbar } = useSnackbar();
  const baseUrl = '/shared_registry'

  React.useEffect(() => {
    if (error?.message) {
      enqueueSnackbar(error?.message, {variant: "error"});
    }
  }, [error])

  const getList = async (page: number, pageSize: number) => {
    await get(`${baseUrl}?page=${page}&size=${pageSize}`);
    return response.json();
  }

  const getOne = async (id: string) => {
    await get(`${baseUrl}/${id}`);
    return response.json();
  }

  const create = async (data: SharedRegistry) => {
    await post(`${baseUrl}`, data)
    if (response?.ok) {
      enqueueSnackbar('Product added', {variant: "success"});
    }
    return response.json();
  }

  const update = async (data: SharedRegistry) => {
    await put(`${baseUrl}/${data.id}`, data)
    if (response?.ok) {
      enqueueSnackbar('Changes saved', {variant: "success"});
    }
    return response.json();
  }

  const remove = async (id: string) => {
    await del(`${baseUrl}/${id}`)
  }

  return {loading, create, update, remove, getList, getOne}
}

export default useProducts;

