import React, {useEffect, useMemo} from "react";
import {Box, Button, Card, Divider, IconButton, Typography} from "@mui/material";
import {DataGrid, GridValueGetterParams, GridColDef, GridRowId, GridCellParams} from "@mui/x-data-grid";
import {useNavigate} from "react-router";
import {Category, Subcategory} from "../../misc/ApiTypes";
import ClearIcon from '@mui/icons-material/Clear';
import useCategoryListPage from "./hooks/useCategoryListPage";
import useCategoryListPageSize from "./hooks/useCategoryListPageSize";
import useCategories from "./hooks/useCategories";
import * as _ from 'lodash';

interface ICallbacks {
  deleteRow: (id: string) => void
}

const columns: (callbacks: ICallbacks) => GridColDef[] = (callbacks: ICallbacks) => [
  { field: 'name', headerName: 'Name', width: 300, disableColumnMenu: true, sortable: false},
  {
    field: 'hidden', headerName: 'Hidden', width: 100, disableColumnMenu: true, sortable: false,
    valueGetter: params => params.row.hidden ? 'Yes' : 'No'
  },
  {
    field: 'type', headerName: 'Type', width: 100, disableColumnMenu: true, sortable: false,
    valueGetter: params => _.startCase(params.row.type)
  },
  { field: 'id', headerName: '', sortable: false, disableColumnMenu: true, disableClickEventBubbling: true,
    renderCell: (params: GridCellParams) => {
      if (typeof params.row.hidden === 'undefined') {
        return <div />;
      }
      return <IconButton onClick={event => {
          event.stopPropagation();
          callbacks.deleteRow(params.row.id)
        }}><ClearIcon style={{color: 'red'}}/>
      </IconButton>
    }
  },
];

export default function () {
  const [page, setPage] = useCategoryListPage();
  const [pageSize, setPageSize] = useCategoryListPageSize();
  const {loading, remove, getTree} = useCategories();
  const navigate = useNavigate();
  const [categories, setCategories] = React.useState<Category[]>([]);

  const fetchData = () => {
    getTree().then(response => {
      setCategories(response ?? []);
    });
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  const callbacks: ICallbacks = {
    deleteRow: id => remove(id).then(fetchData)
  }

  const flatten = React.useMemo(() => {
    return _.orderBy(categories, 'name').reduce((previousValue, currentValue: Category) => {
      return [...previousValue, currentValue, ...(currentValue.subCategories || [])]
    }, [] as Array<Category | Subcategory>)
  }, [categories])

  const total = useMemo(() => {
    return flatten?.length || 0;
  }, [flatten]);

  return <div>
    <Box sx={{justifyContent: 'space-between', width: 1, display: 'flex'}}>
      <Typography variant={'h1'}><b>Categories</b></Typography>
      <Button variant={'contained'} color={'primary'} onClick={() => navigate('/category')}>Add category</Button>
    </Box>
    <Divider sx={{my: 2}} />
    <Card>
      <Box sx={{height: '60vh'}}>
        <DataGrid rows={flatten}
                  density="compact"
                  paginationMode={"client"}
                  sortingMode={'client'}
                  columns={columns(callbacks)}
                  page={page}
                  loading={loading}
                  disableSelectionOnClick
                  onPageChange={(page) => {
                    setPage(page);
                  }}
                  onRowClick={(param, event) => {
                    if (typeof param.row.hidden !== 'undefined') {
                      navigate('/category/' + param.row.id)
                    }
                  }}
                  getRowClassName={(params) => typeof params.row.hidden !== 'undefined' ? `parent-row` : 'child-row'}
                  rowsPerPageOptions={[20, 50, 100]}
                  onPageSizeChange={setPageSize}
                  pageSize={pageSize}
                  rowCount={total}
                  pagination
        />
      </Box>
    </Card>
  </div>
}
