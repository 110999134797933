import React from "react";
import useFetch from "use-http";
import {IApiData, Product, ProductInput} from "../../../misc/ApiTypes";
import {useSnackbar} from "notistack";

const usePrivateProducts = (): Partial<IApiData<Product, ProductInput>> => {
  const { get, response, loading, error } = useFetch();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (error?.message) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  }, [error]);

  const getList = async (page: number, pageSize: number, filters?: { [key: string]: string | undefined }) => {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('size', pageSize.toString());

    if (filters) {
      Object.keys(filters).forEach(key => filters[key] && params.append(key, filters[key]!));
    }

    await get(`/private_products?` + params.toString());
    if (response && response.status === 200) {
      return await response?.json();
    }
  }

  return {loading, getList}
}

export default usePrivateProducts;

