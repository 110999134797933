import React from "react";
import {Box, Button, Card, Divider, IconButton, Typography} from "@mui/material";
import {DataGrid, GridValueGetterParams, GridColDef, GridRowId, GridCellParams} from "@mui/x-data-grid";
import {toCurrency} from "../../misc/utils";
import {useNavigate} from "react-router";
import {Brand} from "../../misc/ApiTypes";
import ClearIcon from '@mui/icons-material/Clear';
import useBrandListPage from "./hooks/useBrandListPage";
import useBrands from "./hooks/useBrands";
import useBrandListPageSize from "./hooks/useBrandListPageSize";

interface ICallbacks {
  deleteRow: (id: string) => void
}

const columns: (callbacks: ICallbacks) => GridColDef[] = (callbacks: ICallbacks) => [
  { field: 'name', headerName: 'Name', width: 300, disableColumnMenu: true, sortable: false},
  { field: 'id', headerName: '', sortable: false, disableColumnMenu: true, disableClickEventBubbling: true,
    renderCell: (params: GridCellParams) => <IconButton onClick={event => {
      event.stopPropagation();
      callbacks.deleteRow(params.row.id)
    }}><ClearIcon style={{color: 'red'}}  /></IconButton>
  },
];

export default function () {
  const [page, setPage] = useBrandListPage();
  const [pageSize, setPageSize] = useBrandListPageSize();
  const {loading, getList, remove} = useBrands();
  const navigate = useNavigate();
  const [total, setTotal] = React.useState(0);
  const [brands, setBrands] = React.useState<Brand[]>([]);

  React.useEffect(() => {
    load();
  }, [])

  const load = () => getList(page, pageSize).then(response => {
    setBrands(response.content);
    setTotal(response.totalElements)
  });

  const callbacks: ICallbacks = {
    deleteRow: id => remove(id).then(load),
  }

  return <div>
    <Box sx={{justifyContent: 'space-between', width: 1, display: 'flex'}}>
      <Typography variant={'h1'}><b>Brands</b></Typography>
      <Button variant={'contained'} color={'primary'} onClick={() => navigate('/brand')}>Add brand</Button>
    </Box>
    <Divider sx={{my: 2}} />
    <Card>
      <Box sx={{height: '60vh'}}>
        <DataGrid rows={brands}
                  density="compact"
                  paginationMode={"client"}
                  sortingMode={'client'}
                  columns={columns(callbacks)}
                  page={page}
                  loading={loading}
                  disableSelectionOnClick
                  onPageChange={(page) => {
                    setPage(page);
                  }}
                  onRowClick={(param, event) => {
                    navigate('/brand/' + param.row.id)
                  }}
                  rowsPerPageOptions={[20, 50, 100]}
                  onPageSizeChange={setPageSize}
                  pageSize={pageSize}
                  rowCount={total}
                  pagination
        />
      </Box>
    </Card>
  </div>
}
