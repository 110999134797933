import React from "react";
import useFetch from "use-http";
import {Category, CategoryInput, IApiData, Subcategory} from "../../../misc/ApiTypes";
import {useSnackbar} from "notistack";

const useCategories = (): IApiData<Category, CategoryInput> & {getTree: () => Promise<Array<Category & {subCategories: Subcategory[]}>>} => {
  const { get, post, put, del, response, loading, error, data: resData } = useFetch();
  const {enqueueSnackbar} = useSnackbar();

  React.useEffect(() => {
    if (error?.message) {
      enqueueSnackbar(error?.message, {variant: "error"});
    }
  }, [error])

  const getList = async () => {
    await get(`/category`);
    let content: Category[] = []
    if (response && response.status === 200) {
      content = await response?.json();
    }
    return {
      content,
      totalElements: content.length,
      first: true,
      last: true,
      number: 1,
      numberOfElements: 10,
      size: 10,
      totalPages: 1
    };
  }

  const getTree = async () => {
    await get(`/category/tree`);

    if (response && response.status === 200) {
      return  response?.json();
    }
  }

  const getOne = async (id: string) => {
    await get(`/category/${id}`);
    return response.json();
  }

  const create = async (data: CategoryInput) => {
    await post(`/category`, data)
    if (response?.ok) {
      enqueueSnackbar('Category added', {variant: "success"});
    }
    return response.json();
  }

  const update = async (data: CategoryInput) => {
    await put(`/category/${data.id}`, data)
    if (response?.ok) {
      enqueueSnackbar('Changes saved', {variant: "success"});
    }
    return response.json();
  }

  const remove = async (id: string) => {
    await del(`/category/${id}`)
  }

  return {loading, create, update, remove, getList, getOne, getTree}
}

export default useCategories;

