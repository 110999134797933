import React from "react";
import {Box, Button, Card, Divider, Typography} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import BrandForm from "./components/BrandForm";
import {BrandInput} from "../../misc/ApiTypes";
import useBrands from "./hooks/useBrands";

export default function () {
  const {id} = useParams();
  const navigate = useNavigate();
  const {create, update, getOne} = useBrands();
  const [initial, setInitial] = React.useState<BrandInput>();

  React.useEffect(() => {
    if (id) {
      getOne(id).then(data => {
        setInitial(data);
      });
    }
  }, [id])

  const goToBrands = () => navigate('/brands')

  const onSubmit = React.useCallback((data: BrandInput) => {
    if (id) {
      return update({...data, id}).then(goToBrands);
    } else {
      return create(data).then(goToBrands)
    }
  }, [id, create, update])

  return <div>
    <Box sx={{justifyContent: 'space-between', width: 1, display: 'flex'}}>
      <Typography variant={'h1'}><b>{id ? 'Update' : 'Add'} brand</b></Typography>
    </Box>
    <Divider sx={{my: 2}} />
    <Card sx={{p: 4, display: 'flex', gap: 5, flexDirection: 'row'}}>
      <BrandForm data={initial} onSubmit={onSubmit} onCancel={goToBrands} loading={false} />
    </Card>
  </div>
}
