import {createBrowserRouter, Navigate} from "react-router-dom";
import App from "./App";
import ProductList from "./pages/products/ProductList";
import React from "react";
import EditProduct from "./pages/products/EditProduct";
import BrandList from "./pages/brands/BrandList";
import EditBrand from "./pages/brands/EditBrand";
import CategoryList from "./pages/categories/CategoryList";
import EditCategory from "./pages/categories/EditCategory";
import WishlistIcons from "./pages/wishlist-icons/WishlistIcons";
import SharedRegistryList from "./pages/shared-registry/SharedRegistryList";
import CalendarEventList from "./pages/calendar-event/CalendarEventList";
import EditCalendarEvent from "./pages/calendar-event/EditCalendarEvent";
import EditSharedRegistry from "./pages/shared-registry/EditSharedRegistry";
import PrivateProductList from "./pages/private-products/PrivateProductList";
import Notifications from "./pages/notifications";
import Notification from "./pages/notifications/notification";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [{
      path: 'products',
      element: <ProductList />,
    }, {
      path: 'product/:id',
      element: <EditProduct />,
    }, {
      path: 'product',
      element: <EditProduct />,
    },{
      path: 'private-products',
      element: <PrivateProductList />,
    },{
      path: 'notifications',
      element: <Notifications />,
    }, {
      path: 'notification/:id',
      element: <Notification />,
    },{
      path: 'notification',
      element: <Notification />,
    },{
      path: 'brands',
      element: <BrandList />,
    }, {
      path: 'brand/:id',
      element: <EditBrand />,
    }, {
      path: 'brand',
      element: <EditBrand />,
    }, {
      path: 'calendar-events',
      element: <CalendarEventList />,
    }, {
      path: 'calendar-event/:id',
      element: <EditCalendarEvent />,
    }, {
      path: 'calendar-event',
      element: <EditCalendarEvent />,
    }, {
      path: 'shared-registries',
      element: <SharedRegistryList />,
    }, {
      path: 'shared_registry/:id',
      element: <EditSharedRegistry />,
    }, {
      path: 'shared_registry',
      element: <EditSharedRegistry />,
    }, {
      path: 'categories',
      element: <CategoryList />,
    }, {
      path: 'category/:id',
      element: <EditCategory />,
    }, {
      path: 'category',
      element: <EditCategory />,
    }, {
      path: '',
      element: <Navigate to={'/products'} />
    }, {
      path: 'wishlist-icons',
      element: <WishlistIcons />,
    }],
  }
]);
