import React from "react";
import {Box, Button, Card, Divider, IconButton, Typography} from "@mui/material";
import {DataGrid, GridValueGetterParams, GridColDef, GridRowId, GridCellParams} from "@mui/x-data-grid";
import {toCurrency} from "../../misc/utils";
import useProductListPageSize from "./hooks/useProductListPageSize";
import useProductListPage from "./hooks/useProductListPage";
import useProducts from "./hooks/useProducts";
import {useNavigate} from "react-router";
import {Product} from "../../misc/ApiTypes";
import ClearIcon from '@mui/icons-material/Clear';
import BrandFilter from "./components/BrandFilter";
import TitleFilter from "./components/TitleFilter";
import useProductListBrand from "./hooks/useProductListBrand";
import useProductListTerm from "./hooks/useProductListTerm";
import moment from "moment";
import useShuffleProducts from "./hooks/useShuffleProducts";

interface ICallbacks {
  deleteRow: (id: string) => void
}

const columns: (callbacks: ICallbacks) => GridColDef[] = (callbacks: ICallbacks) => [
  { field: 'title', headerName: 'Title', width: 200, disableColumnMenu: true, sortable: false},
  { field: 'price', headerName: 'Price', width: 150, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.price) {
        return toCurrency(params.row.price)
      }
      return ''
    }
  },
  { field: 'brand', headerName: 'Brand', width: 150, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.brand) {
        return params.row.brand.name
      }
      return ''
    }
  },
  { field: 'gender', headerName: 'Gender', disableColumnMenu: true, sortable: false},
  { field: 'hidden', headerName: 'Hidden', sortable: false, disableColumnMenu: true, disableClickEventBubbling: true,
    valueGetter: (params: GridCellParams) => params.row.hidden === true ? 'Yes' : 'No'
  },
  { field: 'createdAt', headerName: 'Created at', sortable: false, width: 200, disableColumnMenu: true, disableClickEventBubbling: true,
    valueGetter: (params: GridCellParams) => moment(params.row.createdAt).format('L H:mm:ss')
  },
  { field: 'id', headerName: '', sortable: false, disableColumnMenu: true, disableClickEventBubbling: true,
    renderCell: (params: GridCellParams) => <IconButton onClick={event => {
      event.stopPropagation();
      callbacks.deleteRow(params.row.id)
    }}><ClearIcon style={{color: 'red'}}  /></IconButton>
  },
];

export default function () {
  const [page, setPage] = useProductListPage();
  const [pageSize, setPageSize] = useProductListPageSize();
  const {loading, getList, remove} = useProducts();
  const {loading: shuffleLoading, shuffle} = useShuffleProducts();
  const navigate = useNavigate();
  const [total, setTotal] = React.useState(0);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [title, setTitle] = useProductListTerm();
  const [brand, setBrand] = useProductListBrand();

  React.useEffect(() => {
    load();
  }, [page, pageSize, title, brand])

  const load = () => getList(page, pageSize, { title, brand }).then(response => {
    setProducts(response?.content ?? []);
    setTotal(response?.totalElements ?? 0);
  });

  const callbacks: ICallbacks = {
    deleteRow: id => remove(id).then(load),
  }

  const onShuffleClick = () => {
    shuffle().then(load);
  }

  return <div>
    <Box sx={{justifyContent: 'space-between', width: 1, display: 'flex'}}>
      <Typography variant={'h1'}><b>Products</b></Typography>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <TitleFilter initial={title} onChange={setTitle} />
        <BrandFilter initial={brand} onChange={setBrand} />
        <Button variant={'outlined'} color={'primary'} onClick={onShuffleClick}>Shuffle products</Button>
        <Button variant={'contained'} color={'primary'} onClick={() => navigate('/product')}>Add product</Button>
      </Box>
    </Box>
    <Divider sx={{my: 2}} />
    <Card>
      <Box sx={{height: '60vh'}}>
        <DataGrid rows={products}
                  density="compact"
                  paginationMode={"server"}
                  sortingMode={'server'}
                  columns={columns(callbacks)}
                  page={page}
                  loading={loading || shuffleLoading}
                  disableSelectionOnClick
                  onPageChange={(page) => {
                    setPage(page);
                  }}
                  onRowClick={(param, event) => {
                    navigate('/product/' + param.row.id)
                  }}
                  rowsPerPageOptions={[20, 50, 100]}
                  onPageSizeChange={setPageSize}
                  pageSize={pageSize}
                  rowCount={total}
                  pagination
        />
      </Box>
    </Card>
  </div>
}
