import React from "react";
import useFetch from "use-http";
import {IApiData, Subcategory, SubcategoryInput} from "../../../misc/ApiTypes";
import {useSnackbar} from "notistack";

const useSubcategories = (): IApiData<Subcategory, SubcategoryInput> & {getByCategory(categoryId: string): Promise<Subcategory[]>} => {
  const { get, post, put, del, response, loading, error } = useFetch();
  const {enqueueSnackbar} = useSnackbar();

  React.useEffect(() => {
    if (error?.message) {
      enqueueSnackbar(error?.message, {variant: "error"});
    }
  }, [error])

  const getByCategory = async (categoryId: string) => {
    await get(`/sub_category/category/` + categoryId);
    return response.json();
  }

  const getList = async () => {
    await get(`/sub_category/`);
    const content = await response.json();
    return {
      content,
      totalElements: content.length,
      first: true,
      last: true,
      number: 1,
      numberOfElements: 10,
      size: 10,
      totalPages: 1
    };
  }

  const getOne = async (id: string) => {
    await get(`/sub_category/${id}`);
    return response.json();
  }

  const create = async (data: SubcategoryInput) => {
    await post(`/sub_category`, data)
    if (response?.ok) {
      enqueueSnackbar('Category added', {variant: "success"});
    }
    return response.json();
  }

  const update = async (data: SubcategoryInput) => {
    await put(`/sub_category/${data.id}`, data)
    if (response?.ok) {
      enqueueSnackbar('Changes saved', {variant: "success"});
    }
    return response.json();
  }

  const remove = async (id: string) => {
    await del(`/sub_category/${id}`)
  }

  return {loading, create, update, remove, getList, getOne, getByCategory}
}

export default useSubcategories;

