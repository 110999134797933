import React from 'react';
import {
  AppBar, Badge,
  Box, Button,
  CssBaseline,
  Divider,
  Drawer, IconButton,
  List,
  ListItem, ListItemButton,
  ListItemIcon, ListItemText, Menu, MenuItem, TextField,
  Toolbar,
  Typography,
  CircularProgress
} from "@mui/material";
import CategoryIcon from '@mui/icons-material/Category';
import {Link, Outlet} from "react-router-dom";
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ClassIcon from '@mui/icons-material/Class';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import useFetch from "use-http";

const drawerWidth = 240;

function App() {
  const [user, setUser] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [auth, setAuth] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(true);

  const { post, response, error } = useFetch('/auth-check');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (localStorage.getItem('token')) {
      init();
    } else {
      setLoading(false);
    }
  }, []);

  const logout = () => {
    localStorage.removeItem('token');
    document.location.reload();
  };

  const init = async () => {
    await post();
    if (response.ok) {
      setAuth(true);
    }
    setLoading(false);
  }

  const login = async () => {
    if (user.length) {
      if (!password.length) {
        setErrorMessage('Password is required');
        return;
      }
      setLoading(true);
      localStorage.setItem('token', btoa(`${user}:${password}`));
      await post();
      if (response.ok) {
        setAuth(true);
      } else {
        setErrorMessage(error?.name === '401' ? 'Wrong password!' : '');
      }
    }
    setLoading(false);
  };

  if (!auth) {
    return <Box sx={{width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', justifyContent: 'center'}}>
      {!loading && <>
        <TextField
            label="Username"
            value={user}
            sx={{width: 200}}
            onChange={e => setUser(e.target.value)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                setSubmitted(true);
                login();
              }
            }}
            error={submitted && !user.length}
            helperText={(submitted && !user.length) && 'Username is required'}
        />
        <TextField
            label="Password"
            value={password}
            sx={{width: 200}}
            type={'password'}
            onChange={e => setPassword(e.target.value)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                setSubmitted(true);
                login();
              }
            }}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
        />
        <Button variant={'contained'} color={'primary'} onClick={() => {
          setSubmitted(true);
          login();
        }}>SUBMIT</Button>
      </>}
      {loading && <CircularProgress />}
    </Box>
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar>
          <Box sx={{ display: 'flex', flex: 1 }}>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
            >
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar>
          <Typography variant={'h1'}><b>Tings Admin</b></Typography>
        </Toolbar>
        <Divider />
        <List>
          <Link to="products">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary={'Products'} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to="private-products">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary={'Private Products'} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to="brands">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ReceiptLongIcon />
                </ListItemIcon><ListItemText primary={'Brands'} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to="categories">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ClassIcon />
                </ListItemIcon><ListItemText primary={'Categories'} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to="calendar-events">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CalendarMonthIcon />
                </ListItemIcon><ListItemText primary={'Calendar'} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to="wishlist-icons">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <BurstModeIcon />
                </ListItemIcon><ListItemText primary={'Wishlist icons'} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to="notifications">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon><ListItemText primary={'Broadcast notifications'} />
              </ListItemButton>
            </ListItem>
          </Link>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 5 }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
export default App;
