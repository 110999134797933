import React from "react";
import {IconButton, List, ListItem, ListItemText, Stack} from "@mui/material";
import {SubcategoryInput} from "../../../misc/ApiTypes";
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import SubcategoryForm from "./SubcategoryForm";

interface SubcategoryListProps {
    items: SubcategoryInput[],
    onDeleteClick: (item: SubcategoryInput) => void
    onChange: (prev: SubcategoryInput, next: SubcategoryInput) => void,
}

export default function (props: SubcategoryListProps) {
    return <List>
        {props.items.map(item => <SubcategoryListItem item={item} key={item.name} onChange={props.onChange} onDeleteClick={props.onDeleteClick}/>)}
    </List>
}

interface SubcategoryListItemProps {
    item: SubcategoryInput,
    onDeleteClick: (item: SubcategoryInput) => void,
    onChange: (prev: SubcategoryInput, next: SubcategoryInput) => void,
}

const SubcategoryListItem = (props: SubcategoryListItemProps) => {
    const [editMode, setEditMode] = React.useState(!props.item.name);

    const onChange = async (values: {name: string}) => {
        props.onChange(props.item,{...props.item, ...values});
        setEditMode(false)
        return false
    };

    if (editMode) {
        return <ListItem>
            <SubcategoryForm data={props.item} onSubmit={onChange} onCancel={() => onChange({name: ''})} loading={false} />
        </ListItem>
    }

    return <ListItem
        secondaryAction={
            <Stack direction={'row'} spacing={1}>
                <IconButton onClick={() => setEditMode(true)}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={() => props.onDeleteClick(props.item)}>
                    <ClearIcon style={{color: 'red'}}/>
                </IconButton>
            </Stack>}>
        <ListItemText
            primary={props.item.name}
        />
    </ListItem>
}
