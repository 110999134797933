import React from "react";
import {Box, Button, Card, Divider, IconButton, Typography} from "@mui/material";
import {DataGrid, GridValueGetterParams, GridColDef, GridRowId, GridCellParams} from "@mui/x-data-grid";
import {useNavigate} from "react-router";
import ClearIcon from '@mui/icons-material/Clear';
import useCalendarEvents from "./hooks/useCalendarEvent";
import {CalendarEvent} from "../../misc/ApiTypes";
import useCalendarEventListPage from "./hooks/useCalendarEventListPage";
import useCalendarEventListPageSize from "./hooks/useCalendarEventListPageSize";

interface ICallbacks {
  deleteRow: (id: string) => void
}

const columns: (callbacks: ICallbacks) => GridColDef[] = (callbacks: ICallbacks) => [
  { field: 'name', headerName: 'Name', width: 200, disableColumnMenu: true, sortable: false},
  { field: 'eventDate', headerName: 'Event date', width: 200, disableColumnMenu: true, sortable: false },

  { field: 'id', headerName: '', sortable: false, disableColumnMenu: true, disableClickEventBubbling: true,
    renderCell: (params: GridCellParams) => <IconButton onClick={event => {
      event.stopPropagation();
      callbacks.deleteRow(params.row.id)
    }}><ClearIcon style={{color: 'red'}}  /></IconButton>
  },
];

export default function () {
  const [page, setPage] = useCalendarEventListPage();
  const [pageSize, setPageSize] = useCalendarEventListPageSize();
  const {loading, getList, remove} = useCalendarEvents();
  const navigate = useNavigate();
  const [total, setTotal] = React.useState(0);
  const [calendarEvents, setCalendarEvents] = React.useState<CalendarEvent[]>([]);

  React.useEffect(() => {
    load()
  }, [page, pageSize])

  const load = () => getList(page, pageSize).then(response => {
    setCalendarEvents(response?.content ?? []);
    setTotal(response?.totalElements ?? 0);
  });

  const callbacks: ICallbacks = {
    deleteRow: id => remove(id).then(load),
  }

  return <div>
    <Box sx={{justifyContent: 'space-between', width: 1, display: 'flex'}}>
      <Typography variant={'h1'}><b>Calendar Events</b></Typography>
      <Button variant={'contained'} color={'primary'} onClick={() => navigate('/calendar-event')}>Add event</Button>
    </Box>
    <Divider sx={{my: 2}} />
    <Card>
      <Box sx={{height: '60vh'}}>
        <DataGrid rows={calendarEvents}
                  density="compact"
                  paginationMode={"server"}
                  sortingMode={'server'}
                  columns={columns(callbacks)}
                  page={page}
                  loading={loading}
                  disableSelectionOnClick
                  onPageChange={(page) => {
                    setPage(page);
                  }}
                  onRowClick={(param, event) => {
                    navigate('/calendar-event/' + param.row.id)
                  }}
                  rowsPerPageOptions={[20, 50, 100]}
                  onPageSizeChange={setPageSize}
                  pageSize={pageSize}
                  rowCount={total}
                  pagination
        />
      </Box>
    </Card>
  </div>
}
