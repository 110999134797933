import React from "react";
import * as yup from 'yup';
import {useFormik} from "formik";
import {
  Box,
  Button, Checkbox, FormControlLabel, FormGroup,
  TextField
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {SharedRegistry, SharedRegistryType} from "../../../misc/ApiTypes";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import moment from "moment";


const validationSchema = yup.object({
  name: yup
    .string()
    .required('Name is required'),
  registryDate: yup
    .string()
    .typeError('Date is required')
    .test('registryDate', 'Date is not valid', value => {
      const val = moment(value);
      return val.isValid() && val.isAfter(moment())
    })
    .required('Date is required'),
});

interface SharedRegistryFormProps {
  data?: SharedRegistry,
  onSubmit: (values: SharedRegistry) => Promise<any>,
  onCancel: () => void,
  loading: boolean,
}

const SharedRegistryForm = (props: SharedRegistryFormProps) => {
  const formik = useFormik({
    initialValues: {
      name: props.data?.name || '',
      private: props.data?.private || false,
      type: props.data?.type || SharedRegistryType.Registry,
      registryDate: props.data?.registryDate ? moment(props.data?.registryDate) : null,
      collaborators: props.data?.collaborators || [],
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      props.onSubmit({
        ...values,
        registryDate: moment(values.registryDate).format('YYYY-MM-DD')
      });
    },
  });

  return (
    <Box sx={{width: 400}} component={'form'} onSubmit={formik.handleSubmit} className={'mt-15'}>
      <Grid container spacing={4}>
        <Grid xs={12}>
          <TextField
            fullWidth
            name="name"
            label="Name*"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid xs={6}>
          <DesktopDatePicker
            label="Date*"
            inputFormat="MM/DD/YYYY"
            disablePast
            value={formik.values.registryDate}
            onChange={(value: any) => formik.setFieldValue('registryDate', value)}
            renderInput={(params: any) => <TextField
              {...params}
              fullWidth
              name="registryDate"
              error={formik.touched.registryDate && Boolean(formik.errors.registryDate)}
              helperText={formik.touched.registryDate && formik.errors.registryDate}
            />}
          />
        </Grid>
        <Grid xs={6}>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={formik.values.private}
                                                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                   formik.setFieldValue('private', event.target.checked);
                                                 }} />} label="Private" />
          </FormGroup>
        </Grid>
        <Grid container alignItems={"center"} direction={'row'}>
          <Grid>
            <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
          </Grid>
          <Grid>
            <Button disabled={props.loading} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SharedRegistryForm;
