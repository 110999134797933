import React from "react";
import useFetch from "use-http";
import {IApiData, Notification, NotificationInput} from "../../../misc/ApiTypes";
import {useSnackbar} from "notistack";

const useBroadcast = (): IApiData<Notification, NotificationInput> => {
    const {get, post, put, del, response, loading, error} = useFetch();
    const {enqueueSnackbar} = useSnackbar();

    React.useEffect(() => {
        if (error?.message) {
            enqueueSnackbar(error?.message, {variant: "error"});
        }
    }, [error]);

    const getList = async (page: number, pageSize: number, filters?: { [key: string]: string | undefined }) => {
        const params = new URLSearchParams();
        params.append('page', page?.toString());
        params.append('size', pageSize?.toString());

        if (filters) {
            Object.keys(filters).forEach(key => filters[key] && params.append(key, filters[key]!));
        }

        await get(`/broadcast?` + params?.toString());
        if (response && response.status === 200) {
            return await response?.json();
        }
    }

    const getOne = async (id: string) => {
        await get(`/broadcast?id=${id}`);

        return await response?.json();
    }

    const getOneById = async (id: string) => {
        await get(`/broadcast?id=${id}`);

        return await response?.json();
    }

    const create = async (data: NotificationInput) => {
        await post(`/broadcast`, data)
        if (response?.ok) {
            enqueueSnackbar('Notification added', {variant: "success"});
        }
        return response.json();
    }

    const update = async (data: NotificationInput) => {
        await put(`/broadcast/${data.id}`, data)
        if (response?.ok) {
            enqueueSnackbar('Changes saved', {variant: "success"});
        }
        return response.json();
    }

    const remove = async (id: string) => {
        await del(`/broadcast/${id}`)
    }

    return {loading, create, update, remove, getList, getOne, getOneById}
}

export default useBroadcast;

