import React from "react";
import * as yup from 'yup';
import {useFormik} from "formik";
import {
  Box,
  Button,
  TextField
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {Brand} from "../../../misc/ApiTypes";


const validationSchema = yup.object({
  name: yup
    .string()
    .required('Name is required'),
});

type BrandFormValues = Omit<Brand, 'id'>

interface BrandFormProps {
  data?: BrandFormValues,
  onSubmit: (values: BrandFormValues) => Promise<any>,
  onCancel: () => void,
  loading: boolean,
}

const BrandForm = (props: BrandFormProps) => {
  const formik = useFormik({
    initialValues: {
      name: props.data?.name || '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      props.onSubmit({
        ...values,
      });
    },
  });

  return (
    <Box sx={{width: 400}} component={'form'} onSubmit={formik.handleSubmit} className={'mt-15'}>
      <Grid container spacing={4}>
        <Grid xs={12}>
          <TextField
            fullWidth
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid container alignItems={"center"} direction={'row'}>
          <Grid>
            <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
          </Grid>
          <Grid>
            <Button disabled={props.loading} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BrandForm;
