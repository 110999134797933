import { createTheme } from '@mui/material';

interface Color {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export const colors: {
  text: {primary: string, secondary: string},
  primary: Color,
  secondary: Color,
  warning: Color,
} = {
  text: {
    primary: '#1C434F',
    secondary: '#778E95',
  },
  primary: {
    "50": "#EBF2FF",
    "100": "#D8E6FF",
    "200": "#C4DAFF",
    "300": "#9DC2FF",
    "400": "rgb(40,51,69)",
    "500": "#4F91FF",
    "600": "rgb(30,41,59)",
    "700": "#2264D1",
    "800": "#1B4EA3",
    "900": "rgb(21,29,48)",
  },
  secondary: {
    "50": "#EDF6EE",
    "100": "#DCEDDD",
    "200": "#CBE5CC",
    "300": "#A9D3AB",
    "400": "#87C289",
    "500": "#65B168",
    "600": "#07B422",
    "700": "#37833B",
    "800": "#2B662E",
    "900": "#1F4921",
  },
  warning: {
    "50": "#FFF8EA",
    "100": "#FFF1D6",
    "200": "#FFEAC1",
    "300": "#FFDC99",
    "400": "#FFCF70",
    "500": "#FFC147",
    "600": "#FFB41F",
    "700": "#D1941A",
    "800": "#A37314",
    "900": "#74520F",
  }
};

export const AppTheme = createTheme({
  palette: {
    primary: {
      light: colors.primary["400"],
      main: colors.primary["600"],
      dark: colors.primary["900"]
    },
    secondary: {
      light: colors.secondary["400"],
      main: colors.secondary["700"],
      dark: colors.secondary["900"]
    },
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    },
    background: {
      default: '#f1f5f9',
      paper: 'white',
    },

},
  shadows: [
    'none',
    '0px 2px 4px rgba(0, 0, 0, 0.15)',
    '0px 3px 5px rgba(0, 0, 0, 0.15)',
    '0px 4px 6px rgba(0, 0, 0, 0.15)',
    '0px 5px 7px rgba(0, 0, 0, 0.15)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: 'white',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          color: colors.primary["600"]
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          'div&': {
            margin: 0,
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.primary["600"],
          '& *': {
            color: 'rgb(226,232,240)!important',
          },
          '& hr': {
            borderColor: 'rgb(226,232,240)!important',
          },
          '& .MuiListItemIcon-root': {
            minWidth: 37
          },
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
          backgroundColor: 'white',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
        }
      }
    },
  },
  typography: {
    fontFamily: [
      'Roboto', 'Helvetica Neue', 'sans-serif'
    ].join(','),
    allVariants: {
      color: colors.text.primary,
    },
    h1: {
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: '32.5px'
    },
    h2: {
      fontSize: '24px',
      fontWeight: 400
    },
    h3: {
      fontSize: '18px',
    } ,
    h4: {
      fontSize: '16px',
      fontWeight: 400,
    },
    h5: {
      fontSize: '12px',
      fontWeight: 400,
    },
    h6: {
      fontSize: '10px',
      fontWeight: 400,
    },
    body1: {
       fontSize: 13,
    },
    body2: {
       fontSize: 14,
    },
  },
});
