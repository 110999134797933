import React from "react";
import useFetch from "use-http";
import {Brand, BrandInput, IApiData} from "../../../misc/ApiTypes";
import {useSnackbar} from "notistack";

const useBrands = (): IApiData<Brand, BrandInput> => {
  const { get, post, put, del, response, loading, error } = useFetch();
  const {enqueueSnackbar} = useSnackbar();

  React.useEffect(() => {
    if (error?.message) {
      enqueueSnackbar(error?.message, {variant: "error"});
    }
  }, [error])

  const getList = async () => {
    await get(`/brand-list`);
    let content: Brand[] = []
    if(response && response.status === 200) {
       content = await response?.json();
    }

    return {
      content,
      totalElements: content.length,
      first: true,
      last: true,
      number: 1,
      numberOfElements: 10,
      size: 10,
      totalPages: 1
    };
  }

  const getOne = async (id: string) => {
    await get(`/brand/${id}`);
    return response.json();
  }

  const create = async (data: BrandInput) => {
    await post(`/brand`, data)
    if (response?.ok) {
      enqueueSnackbar('Brand added', {variant: "success"});
    }
    return response.json();
  }

  const update = async (data: BrandInput) => {
    await put(`/brand/${data.id}`, data)
    if (response?.ok) {
      enqueueSnackbar('Changes saved', {variant: "success"});
    }
    return response.json();
  }

  const remove = async (id: string) => {
    await del(`/brand/${id}`)
  }

  return {loading, create, update, remove, getList, getOne}
}

export default useBrands;

