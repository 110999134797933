import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { Brand } from '../../../misc/ApiTypes';
import useBrands from '../../brands/hooks/useBrands';

const BrandFilter = ({ onChange, initial }: { initial?: string, onChange: (id?: string) => void; }) => {
  const [selected, setSelected] = React.useState<string | undefined>(initial);
  const [brands, setBrands] = React.useState<Brand[]>([]);
  const { getList } = useBrands();

  React.useEffect(() => {
    getList(0, 9999).then(res => setBrands(res.content))
  }, [])

  React.useEffect(() => {
    onChange(selected);
  }, [selected])

  return <FormControl sx={{ minWidth: 120 }} size="small">
    <InputLabel id="brandId-label">Brand</InputLabel>
    <Select
      labelId="brandId-label"
      label={'Brand'}
      value={selected}
      onChange={event => {
        setSelected(event.target.value as string);
      }}
    >
      <MenuItem value={''}>All</MenuItem>
      {brands.map(brand => <MenuItem key={brand.id} value={brand.id}>{brand.name}</MenuItem>)}
    </Select>
	</FormControl>
}

export default BrandFilter;